@import "../../node_modules/uikit/dist/css/uikit.css";
/* Small stuff */
/* Phone landscape and bigger */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Large screen and bigger */
.text-center {
  text-align: center;
}
.height-xlarge {
  height: 37.5rem;
}
@media (max-width:  639px ) {
  .height-xlarge {
    height: 28.125rem;
  }
  .height-xlarge.video {
    height: 18.75rem;
  }
}
.height-large {
  height: 28.125rem;
}
@media (max-width:  639px ) {
  .height-large.video {
    height: 18.75rem;
  }
}
.height-medium {
  height: 18.75rem;
}
.height-small {
  height: 9.375rem;
}
.height-full-page {
  min-height: calc(100vh -  18.75rem );
}
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
html,
body {
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: subpixel-antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: geometricPrecision;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-overflow-scrolling: touch !important;
}
@media (max-width:  639px ) {
  html,
  body {
    font-size: 12px;
  }
}
html.wf-loading,
body.wf-loading {
  font-size: 14px;
}
body {
  overflow-y: scroll;
}
* {
  font-family: "futura-pt", sans-serif;
}
.uk-navbar {
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.uk-navbar-container:not(.uk-navbar-transparent) {
  background-color: #fff;
  border-top: solid 1px #a6a6a6;
  border-bottom: solid 1px #a6a6a6;
  box-shadow: 0px 0.125rem 0.25rem 0px rgba(33, 33, 33, 0.15);
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 5rem;
  margin: 0 1.5rem;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a {
  height: initial;
  font-family: "futura-pt", Arial, sans-serif;
  color: #607d8b;
  font-weight: 300;
  font-size: 1rem;
  padding: 0;
  min-height: 5rem;
  border-bottom: 4px solid transparent;
  transition: border 0.25s ease-in-out;
}
@media (max-width:  639px ) {
  .uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a {
    font-size: 0.9375rem;
  }
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active {
  color: #263238;
  font-weight: 400;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.cyan,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.cyan {
  border-bottom: 4px solid #80deea;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.yellow,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.yellow {
  border-bottom: 4px solid #fff59d;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.amber,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.amber {
  border-bottom: 4px solid #ffd54f;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.orange,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.orange {
  border-bottom: 4px solid #ffab91;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.deeporange,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.deeporange {
  border-bottom: 4px solid #ff8a65;
}
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a.active.purple,
.uk-navbar-container:not(.uk-navbar-transparent) .uk-navbar-nav li a:hover.purple {
  border-bottom: 4px solid #ce93d8;
}
h1 {
  color: #263238;
  font-size: 4.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}
h1.header-title {
  height: 8.75rem;
  line-height: 7.5rem;
  background: #0f2027;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, rgba(255, 171, 145, 0.1), rgba(128, 216, 255, 0.1));
  background: linear-gradient(to right, rgba(255, 171, 145, 0.1), rgba(128, 216, 255, 0.1));
}
h1 a {
  color: #263238;
  transition: color 0.25s ease;
}
h1 a:hover {
  color: #616161;
  text-decoration: none;
}
@media (max-width:  639px ) {
  h1 {
    font-size: 3.75rem;
  }
}
.uk-grid {
  margin-left: 0 !important;
}
.uk-grid .tile:nth-child(6n+1) .tile-card {
  border-top: 5px solid #ff80ab;
}
.uk-grid .tile:nth-child(6n+2) .tile-card {
  border-top: 5px solid #b388ff;
}
.uk-grid .tile:nth-child(6n+3) .tile-card {
  border-top: 5px solid #80d8ff;
}
.uk-grid .tile:nth-child(6n+4) .tile-card {
  border-top: 5px solid #b9f6ca;
}
.uk-grid .tile:nth-child(6n+5) .tile-card {
  border-top: 5px solid #ffd740;
}
.uk-grid .tile:nth-child(6n+6) .tile-card {
  border-top: 5px solid #ff6e40;
}
.uk-grid .tile-content {
  width: 100%;
  height: 100%;
  background-color: rgba(38, 50, 56, 0);
  color: #263238;
  border-radius: 0.75rem;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  box-shadow: 0px 0px 0.75rem 0px rgba(33, 33, 33, 0.1);
  border: 1px #d8d8d8 solid;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}
.uk-grid .tile-content .tile-image-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: calc( 37.5rem  - 30px);
  mix-blend-mode: overlay;
  z-index: 0;
}
.uk-grid .tile-content picture {
  height: 100%;
}
.uk-grid .tile-content picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uk-grid .tile-content:hover {
  text-decoration: none;
  background-color: rgba(38, 50, 56, 0.7);
}
.uk-grid .tile-content:hover .tile-card {
  opacity: 1;
}
.uk-grid .tile-card {
  padding: 0.75rem 3rem;
  color: #263238;
  background-color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  opacity: 0;
  box-shadow: 0px 0.125rem 0.25rem 0px rgba(33, 33, 33, 0.1);
  border-radius: 0.25rem;
  z-index: 100;
  transition: opacity 0.25s ease-in-out;
}
.uk-grid .tile-card.sixth {
  font-size: 0.875rem;
}
.uk-grid .tile-card:hover {
  text-decoration: none;
}
@media (max-width:  639px ) {
  .uk-grid .tile-card {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
.uk-lightbox {
  background: rgba(0, 0, 0, 0.9);
}
iframe.uk-responsive-width {
  width: 90vw;
  height: calc(90vw * (1080/1920)) !important;
}
.uk-lightbox-toolbar {
  background: transparent;
}
.uk-lightbox-toolbar.uk-lightbox-caption {
  width: 50%;
  margin: 0 auto 1rem auto;
  color: #fff;
  background-color: rgba(17, 17, 17, 0.75);
  font-size: 1rem;
  font-weight: 400;
  padding: 0.75rem;
  border: 1px solid rgba(97, 97, 97, 0.5);
  border-radius: 0.25rem;
  text-align: center;
}
@media (max-width:  639px ) {
  .uk-lightbox-toolbar.uk-lightbox-caption {
    width: 90%;
  }
}
.uk-lightbox-toolbar.uk-lightbox-caption h3 {
  color: #fff;
  font-weight: 500;
  font-size: 1.125rem;
  margin: 0;
}
.uk-lightbox-toolbar.uk-lightbox-caption p {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 400;
  font-size: 1.0625rem;
  margin: 0;
  line-height: 1.0625rem;
  margin-top: 0.5rem;
}
.uk-transition-slide-bottom {
  transform: translateY(100%) translateY(32px);
}
.uk-lightbox-items > * > * {
  max-width: 90vw;
  max-height: 80vh;
}
p.not-found {
  color: #263238;
  font-size: 1.25rem;
  font-weight: 300;
}
footer {
  align-self: flex-end;
  width: 100%;
  height: 1.5rem;
  padding: 2rem 0 1rem 0;
  background-color: #fff;
  font-family: "futura-pt", Arial, sans-serif;
  color: #607d8b;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
}
.design-detail-page,
.about-page {
  padding: 0;
}
.design-detail-page a:hover,
.about-page a:hover {
  text-decoration: none;
}
.design-detail-page .hero,
.about-page .hero {
  width: 100%;
  height: 28.125rem;
  margin: 0;
  padding: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 0.75rem 0px rgba(33, 33, 33, 0.1);
}
.design-detail-page .design-content,
.about-page .design-content {
  padding: 0 3rem;
}
.design-detail-page .uk-article,
.about-page .uk-article {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
}
.design-detail-page .uk-article h2,
.about-page .uk-article h2 {
  color: #263238;
  font-size: 2rem;
  font-weight: 500;
  margin: 2.5rem 1.5rem;
  text-align: center;
}
.design-detail-page .uk-article p.text,
.about-page .uk-article p.text {
  max-width: 50rem;
  color: #263238;
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0 auto 1.5rem auto;
}
.design-detail-page .uk-article p.text.center,
.about-page .uk-article p.text.center {
  text-align: center;
}
.design-detail-page .uk-article .tile,
.about-page .uk-article .tile {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.design-detail-page .uk-article .tile.stacked,
.about-page .uk-article .tile.stacked {
  padding: 0.25rem;
}
.design-detail-page .uk-article .tile img,
.about-page .uk-article .tile img {
  width: 100%;
  margin: auto;
  max-width: 50rem;
  border-radius: 0.75rem;
  transition: opacity 0.25s ease-in-out;
}
.design-detail-page .uk-article .tile img.border,
.about-page .uk-article .tile img.border {
  box-shadow: 0px 0 1px 0px rgba(97, 97, 97, 0.8);
}
.design-detail-page .uk-article .tile:hover img,
.about-page .uk-article .tile:hover img {
  opacity: 0.9;
}
.design-detail-page .uk-article .tile p.caption,
.about-page .uk-article .tile p.caption {
  text-align: center;
  padding: 0.75rem 0;
  margin: 0.5rem 0 0 0;
  box-shadow: 0px 0.125rem 0.25rem 0px rgba(33, 33, 33, 0.1);
  border: 1px solid rgba(97, 97, 97, 0.5);
  border-radius: 0.25rem;
  color: #263238;
  font-size: 1.125rem;
  font-weight: 300;
}
.design-detail-page .uk-article .tile p.caption span.italic,
.about-page .uk-article .tile p.caption span.italic {
  color: rgba(69, 91, 100, 0.5);
  font-size: 1rem;
  font-style: italic;
}
.design-detail-page .uk-card,
.about-page .uk-card {
  margin: 0.75rem auto 2rem auto;
  padding: 1rem 1.5rem;
  box-shadow: 0px 0.125rem 0.25rem 0px rgba(33, 33, 33, 0.1);
  border: 1px solid rgba(97, 97, 97, 0.5);
  border-top: 0.5rem solid #616161;
  border-radius: 0.25rem;
  text-align: center;
}
.design-detail-page .uk-card h1,
.about-page .uk-card h1 {
  color: #263238;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.design-detail-page .uk-card p,
.about-page .uk-card p {
  margin: 0;
  color: #455b64;
  font-size: 1.125rem;
  font-weight: 300;
}
.design-detail-page .nav-button-container,
.about-page .nav-button-container {
  max-width: 56.25rem;
  margin: auto;
}
.design-detail-page .uk-button,
.about-page .uk-button {
  box-shadow: 0px 0.125rem 0.25rem 0px rgba(33, 33, 33, 0.1);
  border: 1px solid rgba(97, 97, 97, 0.5);
  border-top: 0.3125rem solid #616161;
  border-radius: 0.25rem;
  transition: transform 0.25s ease;
}
.design-detail-page .uk-button:hover,
.about-page .uk-button:hover {
  transform: translateY(-4px);
}
.design-detail-page .pink,
.about-page .pink {
  border-top-color: #ff80ab;
}
.design-detail-page .lavender,
.about-page .lavender {
  border-top-color: #b388ff;
}
.design-detail-page .skyblue,
.about-page .skyblue {
  border-top-color: #80d8ff;
}
.design-detail-page .seafoam,
.about-page .seafoam {
  border-top-color: #b9f6ca;
}
.design-detail-page .tangerine,
.about-page .tangerine {
  border-top-color: #ffd740;
}
.design-detail-page .fiestared,
.about-page .fiestared {
  border-top-color: #ff6e40;
}
.mixed-page .mixed-gallery {
  display: flex;
}
.mixed-page .mixed-tile {
  margin: 0;
  padding: 1rem;
}
.mixed-page .mixed-tile .mixed-tile-content {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-color: rgba(38, 50, 56, 0);
  border-radius: 0.75rem;
  box-shadow: 0px 0px 0.75rem 0px rgba(33, 33, 33, 0.1);
  transition: background-color 0.25s ease-in-out;
}
.mixed-page .mixed-tile img {
  display: block;
  max-width: 100%;
  max-height: none;
  mix-blend-mode: overlay;
}
@media (max-width:  959px ) {
  .mixed-page .mixed-tile img {
    max-width: 100% !important;
    transform: none !important;
  }
}
.mixed-page .mixed-tile .tile-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  transition: opacity 0.25s ease-in-out;
}
.mixed-page .mixed-tile .tile-card.hidden {
  display: none;
}
.mixed-page .mixed-tile.border .mixed-tile-content {
  box-shadow: 0px 0 1px 0px rgba(97, 97, 97, 0.5);
}
.mixed-page .mixed-tile:nth-child(6n+1) .tile-card {
  border-top: 5px solid #ff80ab;
}
.mixed-page .mixed-tile:nth-child(6n+2) .tile-card {
  border-top: 5px solid #b388ff;
}
.mixed-page .mixed-tile:nth-child(6n+3) .tile-card {
  border-top: 5px solid #80d8ff;
}
.mixed-page .mixed-tile:nth-child(6n+4) .tile-card {
  border-top: 5px solid #b9f6ca;
}
.mixed-page .mixed-tile:nth-child(6n+5) .tile-card {
  border-top: 5px solid #ffd740;
}
.mixed-page .mixed-tile:nth-child(6n+6) .tile-card {
  border-top: 5px solid #ff6e40;
}
.mixed-page .mixed-tile:hover {
  cursor: pointer;
}
.mixed-page .mixed-tile:hover .mixed-tile-content {
  background-color: rgba(38, 50, 56, 0.7);
}
.mixed-page .mixed-tile:hover .tile-card {
  opacity: 1;
}
